import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../layouts/mdx';
export const _frontmatter = {
  "date": "2024-10-04T00:00:00.000Z",
  "title": "MitID Controlled Transfer"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Authentication via Criipto Verify now supports starting a session for MitID Controlled Transfer.`}</p>
    <p>{`MitID Controlled Transfer lets you perform cross-broker SSO, that is, transfering an authenticated MitID user
from one service provider to another, without requiring the other service provider to reauthenticate the user.`}</p>
    <p>{`This is useful for cases where shared data services may require a valid MitID authentication to serve data for the user,
but you do not wish to trigger authentication twice for the user.`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.criipto.com/verify/e-ids/danish-mitid/#controlled-transfer"
      }}>{`Read the guide on how to implement Controlled Transfer`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      